import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import CategoryProduct from "../../components/CategoryProduct/CategoryProduct";
import PageHeader from "../../components/PageHeader/PageHeader";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

export default function Products({ data }) {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 100 && window.scrollY <= window.scrollMaxY ) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);


  const [error, setError] = useState(null);
  useEffect(() => {
    try {
      if (!data) {
        throw new Error("Data not available");
      }
    } catch (error) {
      setError(error);
    }
  }, [data]);

  if (error) {
    return <span>{error.map((err) => err.message).join(",")}</span>;
  }

  return (
    <div className="products-section">
      <Layout
        pageTitle={"Products"}
        navData={
          data.allContentfulWebPages.edges[0].node.pages[2].pageLayout
            .navigation
        }
        footerData={
          data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
        }
      >
         <div className={ fixed ? "social-buttons fixed " : "social-buttons"} style={{top: !fixed ?'300px':""}}  >
          <SocialMedia socialMediaData={
          data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
          .navigation.navigationPages[1].pageLayout.layoutMain[1].buttonsContainer
        } />
        </div>
        <PageHeader
          data={
            data.allContentfulWebPages.edges[0].node.pages[2].pageLayout
              .layoutMain[0]
          }
          isLowercaseOnMobile={true}
        />
        {data.allContentfulWebPages.edges[0].node.pages[2].pageLayout.layoutMain[1].containerItems
          .filter(
            (categoryTeaser) =>
              categoryTeaser.category.categoryProducts &&
              categoryTeaser.category.categoryProducts.length > 0
          )
          .map((categoryTeaser, i) => {
            return (
              <CategoryProduct
                data={categoryTeaser}
                key={categoryTeaser.id}
                isRowReversed={i % 2 === 1}
              />
            );
          })}
      </Layout>
    </div>
  );
}

export const productsPageData = graphql`
  query ProductsPageQuery {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            id
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                id
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on ContentfulButtonsContainer {
                        buttonsContainer {
                          socialMediaLink
                          socialMediaTitle
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              layoutMain {
                ... on ContentfulPageHeader {
                  pageHeaderTitle
                  pageHeaderDescription {
                    internal {
                      content
                    }
                  }
                }
                ... on ContentfulContainer {
                  containerItems {
                    ... on ContentfulCategoryTeaser {
                      id
                      category {
                        categoryInnerTitle
                        categorySlug
                        categoryProducts {
                          id
                          product {
                            productInnerTitle
                            productSlug
                          }
                        }
                      }
                      categoryTeaserShareButton {
                        buttonText
                        buttonLink {
                          ... on ContentfulCategory {
                            categorySlug
                          }
                        }
                      }
                      categoryTeaserDescription {
                        internal {
                          content
                        }
                      }
                      categoryTeaserViewMoreButton {
                        buttonText
                        buttonLink {
                          ... on ContentfulCategory {
                            categorySlug
                          }
                        }
                      }
                      categoryTeaserImage {
                        description
                        file {
                          url
                        }
                      }
                    }
                  }
                }
                ... on ContentfulButtonsContainer {
                  buttonsContainer {
                    socialMediaTitle
                    socialMediaIcon {
                      description
                      file {
                        url
                      }
                    }
                    socialMediaLink
                  }
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
  }
`;
